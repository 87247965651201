import React, { useEffect, useState } from "react";

import Evolution from "../assets/images/games-icon/Ezugi.png";
import Aviator from "../assets/images/games-icon/Aviatornew.png";
import CasinoIcon from "../assets/images/icons/casino-icon.png";
import ExchangeIcon from "../assets/images/icons/exchange-icon.png";
import HomeIcon from "../assets/images/icons/home-icon.png";
import SportsIcon from "../assets/images/icons/sports-icon.png";
import DepositIcon from "../assets/images/icons/deposit-icon.png";
import { CasinoProviders } from "../lib/data";
import Qtech from "../assets/images/icons/qtech.png";

export default function BottomNav() {
  const [activePage, setActivePage] = useState("");
  return (
    <div>
      <footer className="footer d-md-none">
        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 ">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "casino/ezugi" ? "active" : ""}
                href={CasinoProviders["ezugi"]?.href}
              >
                <img src={CasinoIcon} />
                <span>Ezugi</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "casino/evolution" ? "active" : ""}
                href={"/casino/evolution"}
              >
                <img src={Evolution} />
                <span>Evolution</span>
              </a>
            </li>

            <li className="home">
              <a className="active" href="/home">
                <img src={HomeIcon} />
                <span>Home</span>
              </a>
            </li>
            <li className="home">
              <a className="active" href="/casino/qtech">
                <img src={Qtech} />
                <span>Qtech</span>
              </a>
            </li>

            <li>
              <a href="/deposit">
                <img src={DepositIcon} />
                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
