import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import EzugiIcon from "../../assets/images/games-icon/casinoezugi-icon.png";
import EvolutionIcon from "../../assets/images/games-icon/Ezugi.png";
import SlotsIcon from "../../assets/images/games-icon/Slots.png";
import XPGIcon from "../../assets/images/games-icon/xpg1.png";
import SupernowaIcon from "../../assets/images/games-icon/Supernova1.png";
import VivoIcon from "../../assets/images/games-icon/VivoGaming.png";

const BottomHead = () => {
  const navigate = useNavigate();
  const headerItems = [
    {
      id: 1,
      name: "Ezugi",
      key: "ezugi",
      redirectionUrl: "/casino/ezugi",
      image: EzugiIcon,
      authRequired: false,
    },
    {
      id: 2,
      name: "Evolution",
      key: "evolution",
      redirectionUrl: "/casino/evolution",
      image: EvolutionIcon,
      authRequired: false,
    },
    {
      id: 3,
      name: "Xpg",
      key: "xpg",
      redirectionUrl: "/casino/xpg",
      image: XPGIcon,
      authRequired: false,
    },
    {
      id: 4,
      name: "Vivo",
      key: "vivo",
      redirectionUrl: "/casino/vivo",
      image: VivoIcon,
      authRequired: false,
    },
    {
      id: 5,
      name: "Supernowa",
      key: "supernowa",
      redirectionUrl: "/casino/supernowa",
      image: SupernowaIcon,
      authRequired: false,
    },
    {
      id: 6,
      name: "Slots",
      key: "slots",
      redirectionUrl: "/Slots",
      image: SlotsIcon,
      authRequired: false,
    },
  ];
  const location = useLocation();

  const checkIfActive = (key) => {
    // console.log("location", location);
    return key === location?.state?.activePage;
  };

  return (
    <div className="Bottom_head d-block d-md-none">
      <ul>
        {Array.isArray(headerItems) &&
          headerItems.map((item, index) => {
            return (
              <li className="" key={index}>
                <a
                  onClick={() => {
                    if (item?.authRequired === false) {
                      navigate(item.redirectionUrl, {
                        state: {
                          activePage: item?.key,
                        },
                      });
                    } else {
                      window.location.href = "/sign-in";
                    }
                  }}
                  className={checkIfActive(item?.key) ? "active" : ""}
                >
                  <img
                    src={item?.image}
                    alt="AvitorImg"
                    className="d-md-none d-block"
                  />
                  <span>{item?.name}</span>
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default BottomHead;
